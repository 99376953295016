import CalendlyWidget from "@/components/CalendlyWidget/CalendlyWidget";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { useCookieConsent } from "@/contexts/CoookieConsentContext";
import CookiesDisabled from "@/components/CookiesDisabled/CookiesDisabled";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `CalendlyBooking`.
 */
export type CalendlyBookingProps =
  SliceComponentProps<Content.CalendlyBookingSlice>;

/**
 * Component for "CalendlyBooking" Slices.
 */
const CalendlyBooking = ({ slice }: CalendlyBookingProps): JSX.Element => {
  const { consentStatus } = useCookieConsent();

  if (consentStatus !== "given") {
    return (
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        id="calendlyBooking"
      >
        <CookiesDisabled />
      </section>
    );
  }

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      id="calendlyBooking"
    >
      <FadeInWhenVisible>
        <Text
          textStyle="h2"
          as="h2"
          textAlign="center"
          padding="70px 0 0 0"
          color={"black"}
        >
          Arrange A Call
        </Text>
        <CalendlyWidget />
      </FadeInWhenVisible>
    </section>
  );
};

export default CalendlyBooking;
